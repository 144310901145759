<template>
  <div>
    <div>
      <div class="weui-header bg-blue fixed">
        <div class="weui-header-left">
          <a class="icon icon-109 f-white" @click="toHome"></a>
        </div>
        <h1 class="weui-header-title">校园卡管理</h1>
        <div class="weui-header-right">
          <a @click="toCardBind" class="icon icon-22 f-white">绑定</a>
        </div>
      </div>
      <div v-if="cardslist.length">
        <div
          class="border-radius w"
          style="margin: 0.5em"
          v-for="item in cardslist"
          :key="item.kh"
        >
          <div class="weui-form-preview">
            <div class="weui-form-preview__bd">
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">卡号</label>
                <span class="weui-form-preview__value">{{ item.number }}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">姓名</label>
                <span class="weui-form-preview__value">{{ item.name }}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">学校名称</label>
                <span class="weui-form-preview__value">{{
                  item.schoolName
                }}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">年级</label>
                <span class="weui-form-preview__value">{{
                  item.gradeName
                }}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">班级</label>
                <span class="weui-form-preview__value">{{
                  item.className
                }}</span>
              </div>
            </div>
            <div class="weui-form-preview__ft">
              <button
                class="weui-form-preview__btn lossbind"
                @click="familyManage(item.studentId)"
                style="color: #10aeff"
              >
                亲情号管理
              </button>
              <button
                class="weui-form-preview__btn lossbind"
                @click="reportLoss(item.cardId)"
                style="color: #10aeff"
              >
                卡片挂失
              </button>
              <button
                class="weui-form-preview__btn freebind"
                @click="untie(item.id)"
                style="color: #10aeff"
              >
                卡片解绑
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p style="text-align: center; padding: 1em">暂无绑定信息卡</p>
      </div>
    </div>
  </div>
</template>

<script>
import global from "../../js/global.js";
export default {
  data() {
    return {
      id: "",
      cardslist: []
    };
  },
  created() {
    this.getCards();
  },
  methods: {
    toHome() {
      this.$router.push("/home");
    },
    toCardBind() {
      this.$router.push("/cardbind");
    },
    async getCards() {
      var that = this;
      // that.$store.commit('showLoading')
      var loading = this.$weui.loading("loading", {
        className: "custom-classname"
      });
      await that
        .$http({
          method: "get",
          url: "/wx/cardList"
        })
        .then(({ data }) => {
          setTimeout(function() {
            loading.hide(function() {});
          }, 500);
          that.cardslist = data.data;
        });
    },
    reportLoss(id) {
      var that = this;
      that.$weui.confirm("挂失后不可恢复？", {
        title: "谨慎操作提示",
        buttons: [
          {
            label: "取消",
            type: "default",
            onClick: function() {}
          },
          {
            label: "确认挂失",
            type: "primary",
            onClick: function() {
              that
                .$http({
                  url: "/wx/reportLoss/" + id,
                  method: "post"
                })
                .then(({ date }) => {
                  that.getCards();
                });
            }
          }
        ]
      });
      // this.getCards ()
    },
    untie(id) {
      var that = this;
      that.$weui.confirm("您确定要解绑吗？", {
        title: "谨慎操作提示",
        buttons: [
          {
            label: "取消",
            type: "default",
            onClick: async function() {}
          },
          {
            label: "确认解绑",
            type: "primary",
            onClick: async function() {
              that
                .$http({
                  url: "/wx/untieCard/" + id,
                  method: "post"
                })
                .then(({ data }) => {
                  that.getCards();
                });
            }
          }
        ]
      });
    },
    familyManage(id) {
      let url = `${window.location.origin}/${global.pathName}${global.pathType}family/manage?id=${id}`;
      window.location.href = url;
    }
  }
};
</script>

<style scoped></style>
