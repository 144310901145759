<template>
  <div>
    <div class="weui-header bg-blue fixed">
      <div class="weui-header-left">
        <a class="icon icon-27 f-white"></a>
      </div>
      <div class="weui-header-title">{{ numberInfo.numberIndex }}</div>
      <div class="weui-header-right">
        <a class="icon icon-22 f-white"></a>
      </div>
    </div>
    <div>
      <img
        :src="numberInfo.numberBackgroundMap"
        style="width: 100%; height: auto"
      />
      <div>
        <div class="weui-panel">
          <div class="weui-panel__hd">
            <h4 class="weui-media-box__title">平台使用说明</h4>
          </div>
          <div class="weui-panel__bd">
            <div class="weui-media-box weui-media-box_text">
              <p
                class="weui-media-box__desc"
                v-for="item in numberInfo.numberInstructions"
                :key="item.content"
              >
                {{ item.content }}
              </p>
              <!-- <p class="weui-media-box__desc">

              </p>
              <p class="weui-media-box__desc">

              </p>
              <p class="weui-media-box__desc">

              </p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="weui-flex" style="margin-top: 0.5em">
        <div class="weui-flex__item">
          <a @click="toCardManage">
            <div class="placeholder f-white button_common">
              <i class="icon icon-30"></i>
              校园卡管理
            </div>
          </a>
        </div>
        <div class="weui-flex__item">
          <a @click="toRecharge">
            <div class="placeholder f-white button_right">
              <i class="icon icon-43"></i>
              充值查询
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from "../../js/global";
export default {
  data() {
    return {
      numberInfo: {
        numberIndex: "智慧校园信息卡平台",
        numberInstructions: [],
        numberBackgroundMap: "../../assets/herder.png"
      }
    };
  },
  created() {
    let that = this;
    let id = global.getQueryVariable("id");
    console.log(localStorage.token);
    if (!localStorage.token || id) {
      console.log(id);
      localStorage.clear();
      var code = global.getQueryVariable("code");
      if (!code) {
        // let id = that.getQueryVariable("id");
        //window.sessionstorage["id"] = id;
        that
          .$http({
            method: "get",
            url: "/wx/wxInfo/" + id
          })
          .then(({ data }) => {
            window.location.href = data.data;
          });
      } else {
        var state = global.getQueryVariable("state");
        that
          .$http({
            url: "/wx/login/" + code + "/" + state,
            method: "get"
          })
          .then(({ data }) => {
            window.localStorage["token"] = data.data;
            window.localStorage["id"] = state;
            // 调用基本信息接口
            that.getIndexInfo();
            that
              .$http({
                method: "get",
                url: "/wx/user/info"
              })
              .then(({ data }) => {
                console.log(data);
                let user = data.data;
                if (user.mobile) {
                  that.$router.replace("/home");
                } else {
                  that.$router.replace("/login");
                }
              });

            //进行页面渲染
          });
      }
    } else {
      that.getIndexInfo();
    }
  },
  methods: {
    getIndexInfo() {
      let that = this;
      let id = localStorage.id;
      that
        .$http({
          url: "/wx/indexInfo/" + id,
          method: "get"
        })
        .then(({ data }) => {
          that.numberInfo = data.data;
          //存储充值金额集合
          localStorage.amounts = data.data.numberAmounts;
          localStorage.familyCount = data.data.publicNumberFamilyCount;
          localStorage.familySwitch = data.data.publicNumberFamilySwitch;
          //进行页面渲染
        });
    },
    toCardManage() {
      this.$router.push("/cardManage");
    },
    toRecharge() {
      var that = this;
      var code = -1;
      that.$router.push("/recharge");
      // if (parseInt(code) > 0) {
      //   // that.$weui.alert('ok')
      //   that.$router.push("/recharge");
      //   // this.$router.push('/cardbind')
      // } else {
      //   // alert('ok')
      //   that.$weui.dialog({
      //     title: "提示",
      //     content: "请先绑定信息卡！",
      //     buttons: [
      //       {
      //         label: "确定",
      //         type: "primary",
      //         onClick: function () {
      //           that.$router.push("/cardbind");
      //         },
      //       },
      //     ],
      //   });
      // }
    }
  }
};
</script>

<style scoped>
.button_common {
  background-color: #ff6600;
  height: 3em;
  line-height: 3em;
}
.button_right {
  background-color: #07c160;
  height: 3em;
  line-height: 3em;
}
.placeholder {
  margin: 5px;
  padding: 0 10px;
  /* background-color: #ebebeb; */
  height: 3em;
  line-height: 3em;
  text-align: center;
  color: #cfcfcf;
}
</style>
