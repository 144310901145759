import { render, staticRenderFns } from "./record.vue?vue&type=template&id=43042f8a&scoped=true&"
import script from "./record.vue?vue&type=script&lang=js&"
export * from "./record.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43042f8a",
  null
  
)

export default component.exports