const pathName="";
const pathType=""
function getQueryVariable(variable) {
  var par = window.location.href.split("?");
  var query = par.length > 1 ? par[1] : "";
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
export default{
  pathName, //地址
  pathType,
  getQueryVariable
}
