<template>
  <div>
    <div class="weui-header bg-blue fixed">
      <div class="weui-header-left">
        <a class="icon icon-109 f-white" @click="goBack"></a>
      </div>
      <h1 class="weui-header-title">充值记录</h1>
      <div class="weui-header-right">
        <a @click="reFresh" class="icon icon-22 f-white"></a>
      </div>
    </div>
    <!-- <div class="weui-cells__title">智慧校园信息卡平台充值记录</div> -->
    <div v-if="recordlist.length > 0">
      <div v-for="item in recordlist" :key="item.number" class="weui-payrec">
        <div class="weui-cells" style="margin-top: 0px">
          <div class="weui-cell weui-cell_access">
            <div class="weui-cell__bd weui-cell_primary">
              <h2 class="weui_cell_title">姓名:{{ item.name }}</h2>
              <h2 class="weui_cell_title">卡号:{{ item.number }}</h2>
              <div class="weui_cell_info">{{ item.payTime }}</div>
            </div>
            <div class="">
              <div class="weui_cell_title">支付成功</div>
              <div class="weui_cell_info">￥{{ item.amount }}元</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="weui-payrec">
      <p style="text-align: center; padding: 1em">暂无通话记录</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      test: "1234",
      recordlist: [],
    };
  },
  created() {
    this.getRecords();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    reFresh() {
      this.reload;
    },
    async getRecords() {
      var that = this;
      var loading = this.$weui.loading("loading", {
        className: "custom-classname",
      });
      await that
        .$http({
          method: "get",
          url: "/wx/rechargeRecord",
        })
        .then(({ data }) => {
          setTimeout(function () {
            loading.hide(function () {});
          }, 500);
          that.recordlist = data.data;
        });
    },
  },
};
</script>

<style  scoped>
</style>
