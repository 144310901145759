<template>
  <div>
    <div class="weui-header bg-blue fixed">
      <div class="weui-header-left">
        <a class="icon icon-109 f-white" @click="toCardManage"></a>
      </div>
      <h1 class="weui-header-title">校园卡绑定</h1>
      <div class="weui-header-right"><a class="icon icon-22 f-white"></a></div>
    </div>
    <!-- <div class="weui-cells__title">绑定智慧校园信息卡</div> -->
    <div class="weui-cell">
      <div class="weui-cell__hd">
        <label for="kdmc" class="weui-label">学校</label>
      </div>
      <div class="weui-cell__bd">
        <!-- <input class="weui-input" @click="selectCard" v-model="kh" name="kh" id="kh" data-card="1111111111" placeholder="请选择" type="text" value="" readonly=""> -->
        <select class="weui-input weui-select" @change="selectSchool($event)">
          <option v-for="item in schools" :key="item.name" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="weui-cell weui-cell_warn"
      style="border-bottom: 1px solid #e5e5e5"
    >
      <div class="weui-cell__hd"><label class="weui-label">卡号</label></div>
      <div class="weui-cell__bd">
        <input
          class="weui-input"
          v-model="number"
          maxlength="10"
          placeholder="请输入卡号"
        />
      </div>
      <div class="weui-cell__ft">
        <button class="weui-vcode-btn" type="button" @click="match">
          查询
        </button>
      </div>
    </div>
    <div class="weui-form-preview__bd" v-show="block_show">
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">姓名</label>
        <span class="weui-form-preview__value" id="xm">{{ card.name }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">学校名称</label>
        <span class="weui-form-preview__value" id="xxmc">{{
          card.schoolName
        }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">年级</label>
        <span class="weui-form-preview__value" id="bjmc">{{
          card.gradeName
        }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">班级</label>
        <span class="weui-form-preview__value" id="bjmc">{{
          card.className
        }}</span>
      </div>
    </div>
    <div class="weui-btn-area">
      <button
        class="weui-btn bg-blue"
        :value="card.id"
        v-show="block_show"
        type="button"
        @click="getbind"
      >
        绑定
      </button>
    </div>
    <div>
      <p class="weui-msg__desc" style="padding-left: 15px;font-size:12px;">
        温馨提醒：卡号已知，请根据卡号进行绑定；
      </p>
      <p class="weui-msg__desc" style="padding-left: 15px;font-size:12px;">
        卡号未知，请点击此处<span @click="addStudent" style="color:red"
          >提交学生信息</span
        >进行操作
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      number: "",
      block_show: false,
      card: {
        name: "",
        id: "",
        schoolName: "",
        className: ""
      },
      schools: [],
      schoolId: 0
    };
  },
  created() {
    let that = this;
    that.getSchools();
  },
  methods: {
    getSchools() {
      let that = this;
      let id = localStorage.id;
      that
        .$http({
          url: "/wx/school/select/" + id,
          method: "get"
        })
        .then(({ data }) => {
          that.schools = data.data;
          that.schoolId = data.data[0].id;
          //进行页面渲染
        });
    },
    selectSchool(event) {
      var that = this;
      var schoolId = event.target.value;
      that.schoolId = schoolId;
    },
    toCardManage() {
      this.$router.push("/cardmanage");
    },
    async match() {
      var that = this;
      var loading = this.$weui.loading("loading", {
        className: "custom-classname"
      });
      var regex = /^[0-9]\d{8}$|^[0-9]\d{9}$/;
      if (regex.test(that.number)) {
        await that
          .$http({
            method: "get",
            url: `/wx/searchCard/${that.schoolId}?number=${that.number}`
          })
          .then(({ data }) => {
            setTimeout(function() {
              loading.hide(function() {});
            }, 500);
            console.log(data.code);
            // if(!data.data){
            //   that.$weui.toast("卡号不存在！",'text')
            // }
            // switch(res.data.code){
            //   case 2:
            //     that.card = res.data.data
            //     that.block_show = true
            //     console.log(that.card.kh)
            //     break;
            //   case 1:
            //     that.$weui.toast("卡号已被绑定！",'text')
            //     break;
            //   case 0:
            //     that.$weui.toast("卡号不存在！",'text')
            //     break;
            // }
            that.card = data.data;
            that.block_show = true;
          });
      } else if (that.number != "") {
        setTimeout(function() {
          loading.hide(function() {});
        }, 500);
        that.$weui.toast("卡号输入错误！", "text");
      } else {
        setTimeout(function() {
          loading.hide(function() {});
        }, 500);
        that.$weui.toast("请输入信息卡号！", "text");
      }
      // this.number = "";
    },
    getbind() {
      var that = this;
      that
        .$http({
          url: `/wx/bindCard/${that.schoolId}/${that.card.id}`,
          method: "post"
        })
        .then(({ data }) => {
          that.$weui.toast("操作成功", {
            duration: 2000,
            className: "custom-classname",
            callback: function() {
              that.block_show = false;
              that.$router.push("/cardmanage");
            }
          });
        });
    },
    addStudent() {
      let that = this;
      that.$router.push("/submit/student");
    }
  }
};
</script>

<style scoped></style>
