<template>
  <div>
    <div class="weui-header bg-blue fixed">
      <div class="weui-header-left">
        <a class="icon icon-109 f-white" @click="back"></a>
      </div>
      <div class="weui-header-title">提交信息</div>
      <div class="weui-header-right">
        <a class="icon icon-22 f-white"></a>
      </div>
    </div>
    <div class="weui-form">
      <!-- 学校列表 -->
      <div class="weui-cell weui-cell_select weui-cell_select-after">
        <div class="weui-cell__hd">
          <label for="" class="weui-label">学校</label>
        </div>
        <div class="weui-cell__bd">
          <select class="weui-input weui-select" @change="selectSchool($event)">
            <option v-for="item in schools" :key="item.name" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- 姓名 -->
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">姓名</label></div>
        <div class="weui-cell__bd">
          <input
            class="weui-input"
            type="text"
            placeholder="请输入姓名"
            v-model="student.name"
          />
        </div>
      </div>
      <!-- 学号 -->
      <div class="weui-cell weui-cell_warn">
        <div class="weui-cell__hd">
          <label for="" class="weui-label">学号</label>
        </div>
        <div class="weui-cell__bd">
          <input
            class="weui-input"
            type="number"
            pattern="[0-9]*"
            value="weui input error"
            placeholder="请输入学号"
            v-model="student.idCard"
          />
        </div>
        <!-- <div class="weui-cell__ft">
          <i class="weui-icon-warn"></i>
        </div> -->
      </div>
      <div class="weui-btn-area">
        <a
          class="weui-btn weui-btn_primary"
          href="javascript:"
          id="showTooltips"
          @click="submitStudent"
          >提交</a
        >
      </div>
    </div>

    <div class="js_dialog" aria-hidden="true" aria-modal="true" v-if="disabled">
      <div class="weui-mask"></div>
      <div class="weui-dialog">
        <div class="weui-dialog__bd">
          信息已提交，请等待反馈消息。
        </div>
        <div class="weui-dialog__ft">
          <a
            role="button"
            href="javascript:"
            class="weui-dialog__btn weui-dialog__btn_primary"
            @click="toHome"
            >知道了</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      student: {
        id: localStorage.id,
        schoolId: 0,
        name: "",
        idCard: ""
      },
      schools: [],
      disabled: false
    };
  },
  created() {
    let that = this;
    that.getSchools();
  },
  methods: {
    toHome() {
      this.$router.push("/home");
    },
    back() {
      this.$router.go(-1);
    },
    getSchools() {
      let that = this;
      let id = localStorage.id;
      that
        .$http({
          url: "/wx/school/select/" + id,
          method: "get"
        })
        .then(({ data }) => {
          that.schools = data.data;
          that.student.schoolId = data.data[0].id;
          //进行页面渲染
        });
    },
    selectSchool(event) {
      var that = this;
      var schoolId = event.target.value;
      that.student.schoolId = schoolId;
    },
    submitStudent() {
      let that = this;
      that
        .$http({
          url: `/wx/submit/student/${localStorage.id}`,
          method: "post",
          data: that.student
        })
        .then(({ data }) => {
          if (data.code == 200) {
            that.disabled = true;
          }
        });
    }
  }
};
</script>

<style scoped>
.button_common {
  background-color: #ff6600;
  height: 3em;
  line-height: 3em;
}
.button_right {
  background-color: #07c160;
  height: 3em;
  line-height: 3em;
}
.placeholder {
  margin: 5px;
  padding: 0 10px;
  /* background-color: #ebebeb; */
  height: 3em;
  line-height: 3em;
  text-align: center;
  color: #cfcfcf;
}
</style>
