<template>
  <div>
    <div class="weui-header bg-blue fixed">
      <!-- <div class="weui-header-left">
        <a class="icon icon-27 f-white"></a>
      </div> -->
      <div class="weui-header-title">绑定手机号</div>
      <!-- <div class="weui-header-right"> -->
      <!-- <a class="icon icon-22 f-white"></a> -->
      <!-- </div> -->
    </div>
    <div>
      <img
        :src="numberInfo.numberBackgroundMap"
        style="width: 100%; height: auto"
      />
      <!-- <div> -->
      <!-- <div class="weui-panel"> -->
      <div class="weui-form">
        <div class="weui-form__control-area">
          <div class="weui-cells weui-cells_form">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">手机号</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  class="weui-input"
                  type="number"
                  pattern="[0-9]*"
                  placeholder="请输入手机号"
                  v-model="bindMpbile.mobile"
                />
              </div>
            </div>
            <div class="weui-cell weui-cell_vcode">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <input
                  class="weui-input"
                  type="tel"
                  placeholder="请输入验证码"
                  v-model="bindMpbile.code"
                />
              </div>
              <div class="weui-cell__ft">
                <button class="weui-vcode-btn" @click="sendCode($event)">
                  获取验证码
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="weui-form__opr-area" style="margin-top: 1.5rem">
          <button
            class="weui-btn weui-btn_primary"
            type="button"
            id="showTooltips"
            @click="bindPhone"
          >
            确定
          </button>
        </div>
      </div>
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import global from "../../js/global.js";
export default {
  data() {
    return {
      numberInfo: {
        numberIndex: "智慧校园信息卡平台",
        numberInstructions: [],
        numberBackgroundMap: "../../assets/herder.png"
      },
      bindMpbile: {
        mobile: "",
        code: ""
      },
      countdown: 0
    };
  },
  created() {
    let that = this;
    that.getIndexInfo();
  },
  methods: {
    getIndexInfo() {
      let that = this;
      let id = localStorage.id;
      that
        .$http({
          url: "/wx/indexInfo/" + id,
          method: "get"
        })
        .then(({ data }) => {
          that.numberInfo = data.data;
          //存储充值金额集合
          localStorage.amounts = data.data.numberAmounts;
          //进行页面渲染
        });
    },
    bindPhone() {
      let that = this;
      that
        .$http({
          url: "/wx/bind/mobile",
          method: "post",
          data: that.bindMpbile
        })
        .then(({ data }) => {
          if (data.code === 200) {
            localStorage.removeItem("token");
            let url = `${window.location.origin}/${global.pathName}${global.pathType}home?id=${localStorage.id}`;
            window.location.href = url;
          } else {
            that.$weui.toast(data.msg, "text");
          }
        });
    },
    sendCode(event) {
      let that = this;
      // 发送验证码接口;
      if (that.bindMpbile.mobile) {
        if (that.countdown === 0) {
          that
            .$http({
              url: `/wx/send/code/${that.bindMpbile.mobile}`,
              method: "post"
            })
            .then(({ data }) => {
              if (data.code == 200) {
                that.loopCode(event);
              } else {
                that.$weui.toast(data.msg, "text");
              }
            });
        }
      } else {
        that.$weui.toast("请输入手机号", "text");
      }
    },
    loopCode(event) {
      let that = this;
      let code = event.target;
      if (that.countdown == 0) {
        code.removeAttribute("disabled");
        code.innerText = "获取验证码";
        that.countdown = 60;
      } else {
        code.innerText = `重新发送(${that.countdown})`;
        code.setAttribute("disabled", true);
        that.countdown--;
      }
      setTimeout(function() {
        that.loopCode(event);
      }, 1000);
    }
  }
};
</script>

<style scoped>
.button_common {
  background-color: #ff6600;
  height: 3em;
  line-height: 3em;
}
.button_right {
  background-color: #07c160;
  height: 3em;
  line-height: 3em;
}
.placeholder {
  margin: 5px;
  padding: 0 10px;
  /* background-color: #ebebeb; */
  height: 3em;
  line-height: 3em;
  text-align: center;
  color: #cfcfcf;
}
</style>
