<template>
  <div>
    <div class="weui-header bg-blue fixed">
      <div class="weui-header-left">
        <a class="icon icon-109 f-white" @click="back"></a>
      </div>
      <div class="weui-header-title">亲情号管理</div>
      <div class="weui-header-right">
        <div @click="save" v-if="familySwitch">保存</div>
        <!-- <a class="icon icon-22 f-white"></a> -->
      </div>
    </div>
    <div class="weui-form">
      <div v-for="(item, index) in familyList">
        <!-- 亲情号 -->
        <div class="weui-cell">
          <div class="weui-cell__hd">
            <label for="" class="weui-label">亲情号</label>
          </div>
          <div class="weui-cell__bd">
            <input
              class="weui-input"
              type="number"
              pattern="[0-9]*"
              value="weui input error"
              placeholder="请输入亲情号"
              :disabled="!familySwitch"
              v-model="item.callNumber"
            />
          </div>
          <div class="weui-cell__ft">
            <i
              v-if="index == 0 && familySwitch && !disabled"
              class="weui-icon-outlined-add2"
              @click="add"
            ></i>
            <i
              v-else-if="index > 0 && familySwitch"
              class="weui-icon-outlined-close2"
              style="color:red"
              @click="del(index)"
            ></i>
          </div>
        </div>
        <div>
          <p class="weui-msg__desc" style="padding: 10px 15px;font-size:12px;">
            温馨提醒：外地号码前加0
          </p>
        </div>
        <!-- 家属 -->
        <div class="weui-cell">
          <div class="weui-cell__hd">
            <label class="weui-label">家属</label>
          </div>
          <div class="weui-cell__bd">
            <input
              class="weui-input"
              type="text"
              placeholder="请输入家属"
              v-model="item.depict"
              :disabled="!familySwitch"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from "../../js/global";
export default {
  data() {
    return {
      disabled: false,
      familyCount: 3,
      familySwitch: false,
      studentId: global.getQueryVariable("id"),
      familyList: [
        {
          callNumber: "",
          depict: ""
        }
      ]
    };
  },
  created() {
    let that = this;
    that.getIndexInfo();
    that.getFamilyList();
    console.log(that.familySwitch);
  },
  methods: {
    getIndexInfo() {
      let that = this;
      let id = localStorage.id;
      that
        .$http({
          url: "/wx/indexInfo/" + id,
          method: "get"
        })
        .then(({ data }) => {
          //存储充值金额集合
          that.familyCount = data.data.publicNumberFamilyCount;
          that.familySwitch = data.data.publicNumberFamilySwitch;
          //进行页面渲染
        });
    },
    getFamilyList() {
      let that = this;
      that
        .$http({
          url: `/wx/family/list/${that.studentId}`,
          method: "get"
        })
        .then(({ data }) => {
          if (data.data.length > 0) {
            that.familyList = data.data;
          }
        });
    },
    back() {
      this.$router.go(-1);
    },
    add() {
      let that = this;
      if (that.familyList.length < that.familyCount) {
        that.familyList.push({
          callNumber: "",
          depict: ""
        });
      } else {
        that.disabled = true;
      }
    },
    del(index) {
      let that = this;
      that.familyList.splice(index, 1);
      if (that.familyList.length < that.familyCount) {
        that.disabled = false;
      }
    },
    save() {
      let that = this;
      that
        .$http({
          url: `/wx/save/family/${localStorage.id}`,
          method: "post",
          data: {
            studentId: that.studentId,
            familyDTOS: that.familyList
          }
        })
        .then(({ data }) => {
          if (data.code == 200) {
            window.location.reload();
          } else {
            that.$weui.toast(data.msg, "text");
          }
        });
    }
  }
};
</script>

<style scoped>
.button_common {
  background-color: #ff6600;
  height: 3em;
  line-height: 3em;
}
.button_right {
  background-color: #07c160;
  height: 3em;
  line-height: 3em;
}
.placeholder {
  margin: 5px;
  padding: 0 10px;
  /* background-color: #ebebeb; */
  height: 3em;
  line-height: 3em;
  text-align: center;
  color: #cfcfcf;
}
</style>
