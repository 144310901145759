import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home/home.vue'
import CardBind from '../components/cardBind/cardbind.vue'
import CardManage from '../components/cardManage/cardManage.vue'
import Recharge from '../components/recharge/recharge.vue'
import Record from '../components/record/record.vue'
import Login from '../components/login/login.vue'
import Student from '../components/student/student.vue'
import Family from '../components/family/familyManage.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/cardbind',
    component: CardBind
  },
  {
    path: '/submit/student',
    component: Student
  },
  {
    path:'/family/manage',
    component: Family
  },
  {
    path: '/cardManage',
    component: CardManage
  },
  {
    path: '/recharge',
    component: Recharge
  },
  {
    path: '/record',
    component: Record
  }
]

const router = new VueRouter({
  mode:"history",
  routes
})

export default router
