import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
// import http from './api/http'
import weui from 'weui.js'
import wx from 'weixin-js-sdk'
// import 'weui'
import './assets/css/global.css'
import './assets/css/weui.css'
import './assets/css/add2.css';
import './assets/css/close2.css';

Vue.prototype.$weui = weui
Vue.prototype.$http = axios
Vue.prototype.$wx = wx
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

Vue.config.productionTip = false

axios.defaults.timeout = 30000
axios.defaults.baseURL="http://116.181.10.205:9527/campus/"
// axios.defaults.baseURL="http://soc.shuweiapp.com/campus/"

// http request 拦截器
axios.interceptors.request.use(
  config=>{
      if(localStorage.token){ //判断token是否存在
          config.headers.Authorization = localStorage.token
      }
      return config
  },
  err=>{
      return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response=>{
      if(response.data.code===401){
          router.replace(`/?id=${window.localStorage.id}`)
          window.localStorage.clear()
          console.log("token 过期")
      }
      return response
  },
  err=>{
      return new Promise().reject(err)
  }
)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
