<template>
  <div>
    <div class="weui-header bg-blue fixed">
      <div class="weui-header-left">
        <a class="icon icon-109 f-white" @click="toHome"></a>
      </div>
      <h1 class="weui-header-title">充值</h1>
      <div class="weui-header-right">
        <a @click="toRecord" class="icon icon-22 f-white">查询</a>
      </div>
    </div>
    <!-- <div class="weui-cells__title">请选择智慧校园信息卡</div> -->

    <div class="weui-cells weui-cells_form">
      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label for="kdmc" class="weui-label">卡号</label>
        </div>
        <div class="weui-cell__bd">
          <!-- <input class="weui-input" @click="selectCard" v-model="kh" name="kh" id="kh" data-card="1111111111" placeholder="请选择" type="text" value="" readonly=""> -->
          <select class="weui-input weui-select" @change="selectCard($event)">
            <option v-for="item in numbers" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>

      <div class="weui-form-preview">
        <div class="weui-form-preview__hd">
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">卡余额</label>
            <em class="weui-form-preview__value" id="amount"
              >¥{{ detail.amount }}</em
            >
          </div>
        </div>
        <div class="weui-form-preview__bd">
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">姓名</label>
            <span class="weui-form-preview__value" id="name">{{
              detail.name
            }}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">学校名称</label>
            <span class="weui-form-preview__value" id="schoolName">{{
              detail.schoolName
            }}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">年级</label>
            <span class="weui-form-preview__value" id="className">{{
              detail.gradeName
            }}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">班级</label>
            <span class="weui-form-preview__value" id="className">{{
              detail.className
            }}</span>
          </div>
        </div>
      </div>
      <div class="weui-pay">
        <h1 class="weui-payselect-title">选择金额付款</h1>
        <ul class="weui-payselect-ul">
          <!-- <li class="weui-payselect-li" @click="setMoney(5000)">
            <a
              data-je="5000"
              :class="{ 'weui-payselect-a': isA, 'weui-payselect-on': isB }"
              >50元</a
            >
            { 'weui-payselect-a': isA, 'weui-payselect-on': isC }
          </li> -->
          <li
            class="weui-payselect-li"
            v-if="!status"
            v-for="(item, index) in amounts"
            :key="item"
            @click="setMoney(index, item)"
          >
            <a
              class="weui-payselect-a"
              :class="{ 'weui-payselect-on': currentIndex == index }"
              >{{ item }}元</a
            >
          </li>

          <li class="weui-payselect-li" v-if="status">
            <a class="weui-payselect-a weui-payselect-on">100元</a>
          </li>

          <!-- <li class="weui-payselect-li" @click="setMoney(10000)">
            <a
              data-je="10000"
              :class="{ 'weui-payselect-a': isA, 'weui-payselect-on': isC }"
              >100元</a
            >
          </li> -->
        </ul>
        <a @click="wePay" id="wxpay" class="weui-btn bg-blue">立即支付</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allcards: [],
      numbers: [],
      detail: "",
      number: "",
      paymoney: 0,
      isA: true,
      isB: false,
      isC: true,
      amounts: [],
      currentIndex: 0,
      status: true
    };
  },
  created() {
    this.getallcards();
    this.prepareSelect();
    // localStorage.clear();
    // localStorage.amounts = [50, 100];
    var loading = this.$weui.loading("loading", {
      className: "custom-classname"
    });
    setTimeout(function() {
      loading.hide(function() {});
    }, 500);
  },
  methods: {
    changeIndex(index) {
      this.currentIndex = index;
    },
    getNumberAmounts() {
      if (!this.status) {
        let amounts = localStorage.amounts;
        if (amounts) {
          amounts = amounts.split(",");
          this.paymoney = amounts[0];
        }
        this.amounts = amounts;
      } else {
        this.paymoney = 100;
      }
    },
    toHome() {
      this.$router.push("/home");
    },
    toRecord() {
      this.$router.push("/record");
    },
    async getallcards() {
      var that = this;
      await that
        .$http({
          method: "get",
          url: "/wx/cardList"
        })
        .then(({ data }) => {
          that.allcards = data.data;
          that.detail = that.allcards[0];
          that.number = that.detail.number;
          that.status = that.detail.status;
          console.log(that.status);
          if (that.status) {
            that.paymoney = 100;
          }
          //调用渲染金额
          this.getNumberAmounts();
          that.allcards.forEach((item, index) => {
            that.numbers.push(item.number);
          });
        });
    },
    selectCard(event) {
      var that = this;
      var kh = event.target.value;
      // 发送请求获取该卡号的所有信息
      that.allcards.forEach((item, index) => {
        if (kh == item.number) {
          that.detail = item;
          that.status = item.status;
          that.getNumberAmounts();
        }
      });
    },
    prepareSelect() {
      // var that = this;
    },
    setMoney(index, money) {
      // var that = this;
      this.paymoney = money;
      this.currentIndex = index;
      // if (this.paymoney == 5000) {
      //   (that.isB = true), (that.isC = false);
      // } else {
      //   (that.isB = false), (that.isC = true);
      // }
    },
    wePay() {
      var loading = this.$weui.loading("loading", {
        className: "custom-classname"
      });
      setTimeout(function() {}, 500);
      var that = this;
      let id = localStorage.id;
      that
        .$http({
          url: `/wx/preOrder/${that.detail.schoolId}/${id}`,
          method: "post",
          data: {
            amount: that.paymoney, //that.paymoney/100
            studentId: that.detail.studentId
          }
        })
        .then(({ data }) => {
          loading.hide(function() {});
          const pay_param = data.data;
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener(
                "WeixinJSBridgeReady",
                that.onBridgeReady,
                false
              );
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady);
              document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady);
            }
          } else {
            that.onBridgeReady(pay_param);
          }
        })
        .catch(error => {
          loading.hide(function() {});
        });
    },
    onBridgeReady(params) {
      var that = this;
      //拉起微信H5支付
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: params.appId,
          timeStamp: params.timeStamp,
          nonceStr: params.nonceStr,
          package: params.package,
          signType: params.signType,
          paySign: params.paySign
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // alert("支付成功");
            that.$router.push("/home");
          } else if (res.err_msg == "stopMonitoringBeacons:ok") {
            // alert("支付成功");
            that.$router.push("/home");
          }
        }
      );
    }
  }
};
</script>

<style scoped></style>
